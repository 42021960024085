import React from 'react';
import { Box, Image } from '@chakra-ui/react';

const RollAnimation = ({ rollImages, isRollLocked, handleImageClick, position }) => {
  return (
  <Box className="roll-animation" mt={8}
  style={{
    zIndex: 1000, // S'assure que l'animation est au-dessus des autres éléments
  }}
>

    <Box className="roll-track">
      {rollImages.map((media, index) => (
        media.endsWith('.mp4') || media.endsWith('.webm') || media.endsWith('.mov') ? (
          <video
            key={index}
            src={media}
            className="roll-media uniform-roll-item"
            poster={`${media}#t=0.1`} // Affiche une miniature (première image)
            preload="metadata"
            muted
            autoPlay
            loop
            playsInline
            controls={false}
            onClick={isRollLocked ? null : handleImageClick} // Désactive le clic si verrouillé
            onContextMenu={isRollLocked ? null : handleImageClick} // Désactive clic droit si verrouillé
            draggable="false"
          />
        ) : (
          <Image
            key={index}
            src={media}
            alt={`roll-${index}`}
            className="roll-image uniform-roll-item"
            onClick={isRollLocked ? null : handleImageClick} // Désactive le clic si verrouillé
            onContextMenu={isRollLocked ? null : handleImageClick} // Désactive clic droit si verrouillé
            draggable="false"
          />
        )
      ))}
    </Box>

    {/* Curseur fixe pour l'animation */}
    <Box className="fixed-cursor"></Box>
  </Box>
);
};

export default RollAnimation;