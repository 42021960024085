import React, { useState, useEffect, useCallback, useRef } from 'react';
import { 
  Box, 
  Button, 
  Input, 
  Select, 
  Textarea, 
  VStack, 
  HStack, 
  Heading, 
  Image, 
  SimpleGrid, 
  FormControl, 
  FormLabel, 
  List, 
  ListItem, 
  useToast,
  Text 
} from '@chakra-ui/react';
import axios from 'axios'; // Import Axios pour récupérer les modèles
import twemoji from 'twemoji';
import CollapsibleBox from '../components/Admin/CollapsibleBox'; // Chaque caisse sera affichée dans un bloc pliable/dépliable.
import SearchBar from "../components/Admin/SearchBar";
import CreateBoxForm from "../components/Admin/CreateBoxForm";
import CreateCarouselForm from "../components/Admin/CreateCarouselForm";
import CarouselList from "../components/Admin/CarouselList";
import StoryList from "../components/Admin/StoryList";
import CreateStoryForm from "../components/Admin/CreateStoryForm";
import SuggestionsManager from "../components/Admin/SuggestionsManager";




function AdminPage() {
  const [models, setModels] = useState([]); // Stocker les modèles récupérés
  const [selectedModel, setSelectedModel] = useState(''); // Stocker l'ID du modèle sélectionné
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [category, setCategory] = useState('Nouveautés');
  const [mediaFiles, setMediaFiles] = useState([]);
  const mediaFilesRef = useRef(null);
  const thumbnailRef = useRef(null);
  const originalVideosRef = useRef(null);
  const [mediaRarities, setMediaRarities] = useState([]);
  const [thumbnail, setThumbnail] = useState(null);
  const [boxes, setBoxes] = useState([]);
  const [storyTitle, setStoryTitle] = useState('');
  const [storyCreator, setStoryCreator] = useState('');
  const [storyAvatar, setStoryAvatar] = useState(null);
  const [storyMedia, setStoryMedia] = useState(null);
  const [stories, setStories] = useState([]);
  const toast = useToast();
  const [priceHT, setPriceHT] = useState(''); // Stocker le prix HT
  const [commission, setCommission] = useState(''); // Stocker la commission
  const [modelRevenue, setModelRevenue] = useState(''); // Stocker le revenu du modèle
  const [selectedCountry, setSelectedCountry] = useState('FR'); // Pays sélectionné (par défaut France)
  const [showEmojiPicker, setShowEmojiPicker] = useState(false); // État pour le picker
  const [searchTerm, setSearchTerm] = useState("");
  const emojis = [
    "😀", // Visage souriant
    "😂", // Rire aux larmes
    "😍", // Émerveillé
    "😘", // Bisou
    "👄", // Lèvres
    "👍", // Pouce levé
    "🍑", // Pêche
    "🍆", // Aubergine
    "💧", // Goutte d'eau
    "❤️", // Cœur rouge
    "🖤", // Cœur noir
    "💜", // Cœur violet
    "💛", // Cœur jaune
    "💖", // Cœur rose
    "💙", // Cœur bleu
    "🔞",  // Emoji -18
    "🔥"  // flàmme


  ];

  
  // Liste des taux de TVA par pays
  const TVA_RATES = {
    FR: 20,
    BE: 21,
    DE: 19,
    LU: 17,
    AT: 20,
    BG: 20,
    HR: 25,
    CY: 19,
    CZ: 21,
    DK: 25,
    EE: 20,
    FI: 24,
    GR: 24,
    HU: 27,
    IE: 23,
    IT: 22,
    LV: 21,
    LT: 21,
    MT: 18,
    NL: 21,
    PL: 23,
    PT: 23,
    RO: 19,
    SK: 20,
    SI: 22,
    ES: 21,
    SE: 25,
    CH: 7.7, // Suisse
    UK: 20, // Royaume-Uni
  };


  const [boxType, setBoxType] = useState('photo'); // "photo" ou "video"
  const [originalVideos, setOriginalVideos] = useState([]); // Pour les fichiers vidéo
  const [videoRarities, setVideoRarities] = useState([]); // Pour les catégories des vidéos
  const [videoDropRates, setVideoDropRates] = useState([]); // Pour les taux de drop des vidéos

  // --- Début des fonctionnalités pour les carrousels ---
  const [carouselImages, setCarouselImages] = useState([]); // Liste des images sélectionnées pour le carrousel
  const [carouselData, setCarouselData] = useState([]); // Liste des carrousels récupérés

  const apiUrl = process.env.REACT_APP_API_URL || 'https://onlybox.fans/api'; // Vérifie bien que cette variable est correctement définie

  const fetchCarouselImages = useCallback(async () => {
    
    try {
      const response = await fetch(`${apiUrl}/api/carousel`); // Utiliser /api/carousel au lieu de /api/admin/carousel
      if (!response.ok) {
        throw new Error('Erreur lors de la récupération des images du carousel');
      }
      const data = await response.json();
      console.log(data); // <-- Ajoute ceci pour vérifier la structure des données
      setCarouselData(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error('Erreur lors de la récupération des images du carousel:', error);
      toast({
        title: 'Erreur',
        description: 'Erreur lors de la récupération des images du carousel.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }, [toast]);


  // Fonction pour gérer l'upload d'images
  const handleCarouselImageUpload = (e) => {
    const files = Array.from(e.target.files);
    const supportedFormats = ['image/webp', 'image/jpeg', 'image/png'];
    if (files.length > 10) {
      toast({
        title: 'Erreur',
        description: 'Vous ne pouvez pas uploader plus de 10 images.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    // Vérification des formats des fichiers
  const invalidFiles = files.filter(file => !supportedFormats.includes(file.type));
  if (invalidFiles.length > 0) {
    toast({
      title: 'Erreur',
      description: 'Seuls les formats WebP, JPEG et PNG sont acceptés.',
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
    return;
  }
    setCarouselImages(files);
  };

  // Fonction pour soumettre le carrousel
  const handleCarouselSubmit = async (e) => {
    e.preventDefault();

    if (carouselImages.length === 0) {
      toast({
        title: 'Erreur',
        description: 'Veuillez sélectionner au moins un fichier image.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const formData = new FormData();
    
    // Ajouter chaque fichier d'image dans le formulaire
    carouselImages.forEach((image) => {
     formData.append('images', image); // Utilisez un champ 'images' pour plusieurs fichiers
    });

    try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(`${apiUrl}/api/admin/carousel`, {
            method: 'POST',
            body: formData,
          });
          const result = await response.json();
          console.log('Images du carousel créées :', result);
          fetchCarouselImages(); // Rafraîchir la liste des images
          setCarouselImages([]); // Réinitialiser le champ des fichiers
          } catch (error) {
          console.error('Erreur lors de la création de l\'image du carousel :', error);
           toast({
           title: 'Erreur',
           description: 'Erreur lors de la création des images du carousel.',
           status: 'error',
           duration: 5000,
           isClosable: true,
         });
       }
     };



     const handleDeleteCarouselImage = async (carouselId, imageIndex) => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        // Requête pour supprimer les images d'un carrousel
        await fetch(`${apiUrl}/api/admin/carousel/${carouselId}/image/${imageIndex}`, {
          method: 'DELETE',
        });
    
        // Met à jour les carrousels après suppression
        setCarouselData((prevData) =>
          prevData.map((carousel) =>
            carousel._id === carouselId
              ? {
                  ...carousel,
                  images: carousel.images.filter((_, index) => index !== imageIndex),
                }
              : carousel
          )
        );
    
        toast({
          title: 'Succès',
          description: 'Image supprimée avec succès.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } catch (error) {
        console.error('Erreur lors de la suppression de l\'image:', error);
        toast({
          title: 'Erreur',
          description: 'Erreur lors de la suppression de l\'image.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    };

  const handleDeleteCarousel = async (id) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      // Requête pour supprimer le carrousel entier
      await fetch(`${apiUrl}/api/admin/carousel/${id}`, {
        method: 'DELETE',
      });
  
      // Met à jour la liste des carrousels après suppression
      setCarouselData((prevData) => prevData.filter((carousel) => carousel._id !== id));
  
      toast({
        title: 'Succès',
        description: 'Carrousel supprimé avec succès.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Erreur lors de la suppression du carrousel:', error);
      toast({
        title: 'Erreur',
        description: 'Erreur lors de la suppression du carrousel.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };




  const fetchBoxes = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/boxes`);
      const data = await response.json();
      setBoxes(data);
    } catch (error) {
      console.error('Erreur lors de la récupération des caisses:', error);
      toast({
        title: 'Erreur',
        description: 'Erreur lors de la récupération des caisses.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }, [toast]);
  
  const fetchStories = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/admin/stories`);
      if (!response.ok) {
        throw new Error('Erreur lors de la récupération des stories');
      }
      const data = await response.json();
      setStories(data);
    } catch (error) {
      console.error('Erreur lors de la récupération des stories:', error);
      toast({
        title: 'Erreur',
        description: 'Erreur lors de la récupération des stories.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }, [toast]);

  // Fonction pour calculer le prix HT, la commission, et le revenu du modèle
const calculateValues = (priceTTC, countryCode = 'FR') => {
  if (priceTTC && !isNaN(priceTTC)) {
    const tvaRate = TVA_RATES[countryCode] || 20; // Récupère le taux de TVA pour le pays, 20% par défaut
    const ht = (priceTTC / (1 + tvaRate / 100)).toFixed(2); // Calcul du prix HT
    let comm = 0;
    let revenue = 0;

    // Si un modèle est sélectionné, calculer la commission et le revenu
    if (selectedModel) {
      comm = (ht * 0.40).toFixed(2); // Commission 40%
      revenue = (ht - comm).toFixed(2); // Revenu du modèle
    } else {
      // Si aucun modèle, tout le HT vous revient
      comm = 0;
      revenue = ht;
    }

    // Mettre à jour les états
    setPriceHT(ht);
    setCommission(comm);
    setModelRevenue(revenue);
  } else {
    // Réinitialiser si le prix n'est pas valide
    setPriceHT('');
    setCommission('');
    setModelRevenue('');
  }
};

  // Récupérer les modèles depuis l'API
  useEffect(() => {
    const fetchModels = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await axios.get(`${apiUrl}/api/admin/models`);
        setModels(response.data); // Stocker les modèles dans le state
      } catch (error) {
        console.error("Erreur lors de la récupération des modèles :", error);
        toast({
          title: 'Erreur',
          description: 'Erreur lors de la récupération des modèles.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    };
    fetchModels(); // Appeler la fonction pour récupérer les modèles
  }, [toast]);

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        fetchBoxes(),
        fetchStories(),
        fetchCarouselImages(),
      ]);
    };
    fetchData();
  }, [fetchBoxes, fetchStories, fetchCarouselImages]);

  // Définir les taux de drop en fonction de la rareté sélectionnée
  const rarityRates = {
    Élémentaire: 79.92,
    Singulier: 15.98,
    Intrigant: 3.2,
    Captivant: 0.64,
    Mythique: 0.26,
  };


  const handleMediaUpload = (e) => {
    const files = Array.from(e.target.files);
    setMediaFiles(files);
    setMediaRarities(files.map(() => 'Élémentaire'));
  };


  const handleVideoUpload = (e) => {
    const files = Array.from(e.target.files);
    
    // Définir les vidéos sélectionnées
    setOriginalVideos(files);
  
    // Initialiser la rareté à "Élémentaire" pour chaque vidéo
    const defaultRarities = files.map(() => 'Élémentaire');
    setVideoRarities(defaultRarities);
  
    // Définir les taux de drop basés sur la rareté "Élémentaire" par défaut
    const defaultDropRates = defaultRarities.map(rarity => rarityRates[rarity]);
    setVideoDropRates(defaultDropRates);
  };

  const handleRarityChange = (index, newRarity) => {
    const updatedRarities = [...mediaRarities];
    updatedRarities[index] = newRarity;
    setMediaRarities(updatedRarities);
  };


  // Ajoute tes nouvelles fonctions ici
const handleRarityChangeForVideo = (index, newRarity) => {
  const updatedRarities = [...videoRarities];
  updatedRarities[index] = newRarity;
  setVideoRarities(updatedRarities);


// Mettre à jour le taux de drop automatiquement en fonction de la rareté
const updatedDropRates = [...videoDropRates];
updatedDropRates[index] = rarityRates[newRarity] || 0; // Par défaut à 0 si la rareté est non définie
setVideoDropRates(updatedDropRates);
};

  const handleThumbnailUpload = (e) => {
    setThumbnail(e.target.files[0]);
  };

  const handleSubmitBox = async (e) => {
    e.preventDefault();
    
    const formData = new FormData();

    // Ajout du modèle sélectionné si disponible
    if (selectedModel) {
      console.log("ID du modèle sélectionné :", selectedModel);
      formData.append('createdByModelId', selectedModel); // Associe l'ID du modèle sélectionné à la caisse
    }

    // Ajout du code pays sélectionné (countryCode)
    formData.append('countryCode', selectedCountry); // IMPORTANT : ajoutez cette ligne
    
    // Calcul du prix HT
  const priceHT = parseFloat((price / 1.2).toFixed(2)); // Prix HT arrondi à deux chiffres
  
  let commission = 0;
  let modelRevenue = 0;

  // Si un modèle est sélectionné, on calcule la commission et le revenu du modèle
  if (selectedModel) {
    commission = parseFloat((priceHT * 0.40).toFixed(2)); // 40% de commission
    modelRevenue = parseFloat((priceHT - commission).toFixed(2)); // Revenu du modèle après commission
  }
    
    formData.append('title', title);
    formData.append('description', description);
    formData.append('price', price); // Prix TTC
    formData.append('priceHT', priceHT); // Prix HT arrondi
    
    // Si un modèle est sélectionné, ajouter commission et revenue du modèle
    if (selectedModel) {
       formData.append('commission', commission); // Commission arrondie
       formData.append('modelRevenue', modelRevenue); // Revenu modèle arrondi
      }
       
       formData.append('category', category);
       console.log("Type de caisse sélectionné :", boxType);
       formData.append('boxType', boxType); // Indique le type de caisse (photo ou vidéo)
       

       // Si la caisse est de type "photo", utilise le champ Médias pour l'aperçu et le rouleau
       if (boxType === 'photo') {
      mediaFiles.forEach((file, index) => {
      formData.append('mediaFiles', file);
      formData.append('mediaRarities', mediaRarities[index]);
    });
    }

    // Si la caisse est de type "vidéo", utilise Médias pour les vignettes et Vidéos Originales pour le rouleau
    else if (boxType === 'video') {
    // Ajoute les captures d'écran en tant que vignettes dans Médias
    mediaFiles.forEach((file, index) => {
      formData.append('mediaFiles', file); // Images de capture d’écran
      formData.append('mediaRarities', mediaRarities[index]);
    });

    originalVideos.forEach((videoFile, index) => {
      formData.append('originalVideos', videoFile); // Vidéos pour le rouleau
      formData.append('videoRarities', videoRarities[index]);

    
    // Vérifier si des vidéos originales sont fournies, puis les ajouter avec des taux de drop basés sur la rareté
    // Ajout des vidéos originales si elles sont fournies

    const rarityRates = {
      Élémentaire: 79.92,
      Singulier: 15.98,
      Intrigant: 3.2,
      Captivant: 0.64,
      Mythique: 0.26,
    };

    
      // Appliquez automatiquement le taux de drop selon la rareté de la vidéo
      const dropRate = rarityRates[videoRarities[index]] || 0;
      formData.append('videoDropRates', dropRate);

    });
  } 
  
    if (thumbnail) {
    formData.append('thumbnail', thumbnail);
    }

    // Ajoutez le code ici pour voir le contenu de formData avant d'envoyer la requête
  for (let pair of formData.entries()) {
    console.log(pair[0] + ', ' + pair[1]);
  }

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/admin/boxes`, {
      method: 'POST',
      body: formData,
      });
      const result = await response.json();
      console.log('Caisse créée avec modèle :', result); // Log les données renvoyées par le serveur

      // Afficher un toast de succès
    toast({
      title: 'Caisse créée !',
      description: `La caisse "${title}" a été créée avec succès.`,
      status: 'success',
      duration: 5000,
      isClosable: true,
    });

      console.log('Caisse créée:', result);
      // Réinitialiser les champs après soumission
      setOriginalVideos([]);
      setVideoRarities([]);
      setVideoDropRates([]);
      setTitle('');
      setDescription('');
      setPrice('');
      setCategory('Nouveautés');
      setMediaFiles([]);
      setThumbnail(null);
      setMediaRarities([]);
      setSelectedModel(''); // Réinitialiser la sélection du modèle
      setBoxType('photo'); // Réinitialiser le type de caisse

       // Utilisez les refs pour effacer les champs de fichier dans le DOM
    if (mediaFilesRef.current) {
      mediaFilesRef.current.value = '';
    }
    if (thumbnailRef.current) {
      thumbnailRef.current.value = '';
    }
    if (originalVideosRef.current) {
      originalVideosRef.current.value = ''; // Vider les vidéos originales
    }

      fetchBoxes(); // Rafraîchissez la liste des caisses
    } catch (error) {
      console.error('Erreur lors de la création de la caisse:', error);
      toast({
        title: 'Erreur',
        description: 'Erreur lors de la création de la caisse.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };


  const handleDeleteBox = async (id) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      await fetch(`${apiUrl}/api/admin/boxes/${id}`, {
      method: 'DELETE',
      });

      fetchBoxes();
      toast({
        title: 'Succès',
        description: 'Caisse supprimée avec succès.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Erreur lors de la suppression de la caisse:', error);
      toast({
        title: 'Erreur',
        description: 'Erreur lors de la suppression de la caisse.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleStorySubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    
    formData.append('title', storyTitle);
    formData.append('creator', storyCreator);
    formData.append('avatar', storyAvatar);
    formData.append('media', storyMedia);

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/admin/stories`, {
      method: 'POST',
      body: formData,
      });

      const result = await response.json();
      console.log('Story créée:', result);
      fetchStories();
      setStoryTitle('');
      setStoryCreator('');
      setStoryAvatar(null);
      setStoryMedia(null);
    } catch (error) {
      console.error('Erreur lors de la création de la story:', error);
      toast({
        title: 'Erreur',
        description: 'Erreur lors de la création de la story.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box p={5}>
      <Heading mb={5}>Admin - Gestion des Caisses</Heading>

       {/* Formulaire de création de caisse fichier CreateBoxForm.js */}
      <CreateBoxForm
        models={models}
        selectedModel={selectedModel}
        setSelectedModel={setSelectedModel}
        selectedCountry={selectedCountry}
        setSelectedCountry={setSelectedCountry}
        TVA_RATES={TVA_RATES}
        price={price}
        setPrice={setPrice}
        priceHT={priceHT}
        commission={commission}
        modelRevenue={modelRevenue}
        title={title}
        setTitle={setTitle}
        description={description}
        setDescription={setDescription}
        category={category}
        setCategory={setCategory}
        boxType={boxType}
        setBoxType={setBoxType}
        mediaFiles={mediaFiles}
        mediaFilesRef={mediaFilesRef}
        mediaRarities={mediaRarities}
        handleMediaUpload={handleMediaUpload}
        handleRarityChange={handleRarityChange}
        originalVideos={originalVideos}
        originalVideosRef={originalVideosRef}
        videoRarities={videoRarities}
        videoDropRates={videoDropRates}
        handleVideoUpload={handleVideoUpload}
        handleRarityChangeForVideo={handleRarityChangeForVideo}
        thumbnailRef={thumbnailRef}
        handleThumbnailUpload={handleThumbnailUpload}
        handleSubmitBox={handleSubmitBox}
        calculateValues={calculateValues}
        emojis={emojis}
        showEmojiPicker={showEmojiPicker}
        setShowEmojiPicker={setShowEmojiPicker}
        
      />
      
      {/* Barre de recherche */}
<SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />

{/* Liste des caisses créées avec filtre */}
<Heading mt={10} mb={5} size="md">Caisses Créées</Heading>
<List spacing={4}>
  {boxes
    .filter((box) => {
      if (!searchTerm) {
        return true; // Si aucun terme de recherche, afficher toutes les caisses
      }

      // Vérifiez si le terme correspond au `username` ou au `createdByModelId`
      const usernameMatch = box.username
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()); // Correspondance avec le `username`
      const idMatch = box.createdByModelId
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()); // Correspondance avec l'ID du modèle

        console.log({
          box: box,
          usernameMatch,
          idMatch
        });

      return usernameMatch || idMatch; // Inclure si l'un des deux correspond
    })

    .map((box) => (
      <ListItem key={box._id}>
        {/* Utilisation de CollapsibleBox */}
        <CollapsibleBox box={box} handleDeleteBox={handleDeleteBox} />
      </ListItem>
    ))}
</List>

<Box p={5}>
      {/* Formulaire de création de carrousel CreateCarouselForm.js */}
      <Heading mt={10} mb={5}>Créer un Carrousel</Heading>
      <CreateCarouselForm
        carouselImages={carouselImages}
        setCarouselImages={setCarouselImages} // Passer le setter pour permettre la réorganisation
        handleCarouselImageUpload={handleCarouselImageUpload}
        handleDeleteCarouselImage={handleDeleteCarouselImage}
        handleCarouselSubmit={handleCarouselSubmit}
      />

      {/* Liste des carrousels créés CarouselList.js */}
      <CarouselList
        carouselData={carouselData}
        handleDeleteCarouselImage={handleDeleteCarouselImage}
        handleDeleteCarousel={handleDeleteCarousel}
      />
    </Box>

      {/* Formulaire de création de story */}
      <Heading mt={10} mb={5}>Créer une Story</Heading>
      <CreateStoryForm
        storyTitle={storyTitle}
        setStoryTitle={setStoryTitle}
        storyCreator={storyCreator}
        setStoryCreator={setStoryCreator}
        storyAvatar={storyAvatar}
        setStoryAvatar={setStoryAvatar}
        storyMedia={storyMedia}
        setStoryMedia={setStoryMedia}
        handleStorySubmit={handleStorySubmit}
      />

      {/* Liste des stories créées */}
      <StoryList stories={stories} />
      
      {/* Gestion des suggestions */}
    <SuggestionsManager apiUrl={apiUrl} boxes={boxes} />
  </Box>
  );
};

export default AdminPage;