// components/HomePage/StoryList.js
import React, { useEffect } from 'react';
import { HStack, Box, Circle, Image, Text, Modal, ModalOverlay, ModalContent, ModalBody, ModalFooter, Button } from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';

import '../../styles/StoryList.css';


const MotionBox = motion(Box);

const StoryList = ({ stories, maxVisibleStories, handleStoryClick, selectedStory, isModalOpen, closeModal, showNextStory, showPreviousStory, apiUrl, initialOpen }) => {
  
  // 🎯 Gestion du clavier (PC) : Échap pour fermer, flèches pour naviguer
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        closeModal(); // Ferme la story
      } else if (event.key === "ArrowRight") {
        showNextStory(); // Story suivante
      } else if (event.key === "ArrowLeft") {
        showPreviousStory(); // Story précédente
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [closeModal, showNextStory, showPreviousStory]);
  
  
  return (
    <>
      {/* Liste des Stories */}
      <HStack
        as={motion.div}
        overflowX="auto"
        spacing={4}
        className="stories-container"
        padding={2}
        justifyContent="center"
        width="100%"
        maxWidth={{ base: "100vw", md: "80vw", lg: "60vw" }}
        alignItems="center"
        css={{
          '&::-webkit-scrollbar': {
            display: 'none', // Cache la scrollbar sur les appareils modernes
          },
        }}
      >
        {stories.slice(0, maxVisibleStories).map((story) => (
          <Box key={story._id} textAlign="center">
            <MotionBox
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              padding="4px"
              borderRadius="full"
              display="flex"
              alignItems="center"
              justifyContent="center"
              onClick={() => handleStoryClick(story)}
              sx={{
                bgGradient: "linear(to-r, pink.500, orange.500, yellow.500, green.500, blue.500, purple.500)",
                padding: "4px",
                borderRadius: "full",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                overflow: "hidden", // S'assurer que le contenu reste rond
              }}
            >
              <Circle size="72px" bg="white" overflow="hidden">
                <Image
                  src={`${apiUrl}/uploads/avatars/${story.avatar}`}
                  alt={story.creator}
                  boxSize="64px"
                  borderRadius="full"
                  objectFit="cover"
                  opacity={story.viewed ? 0.7 : 1} // Rendre l'image plus terne si visionnée
                />
              </Circle>
            </MotionBox>
            <Text fontSize="sm" mt={2} color="white">
              {story.creator}
            </Text>
          </Box>
        ))}
      </HStack>

      {/* Modal pour afficher les détails d'une Story */}
      {selectedStory && (
      <Modal isOpen={isModalOpen} onClose={closeModal} size="full">
      <ModalOverlay />
      <ModalContent bg="rgba(0, 0, 0, 0.5)" position="relative">
      <ModalBody p="0" m="0" position="relative">
              <AnimatePresence mode="wait">
                <motion.div
                  key={selectedStory._id}
                  initial={initialOpen ? { opacity: 0, y: 50 } : { opacity: 0, x: 100 }}
                  animate={{ opacity: 1, x: 0, y: 0 }}
                  exit={initialOpen ? { opacity: 0, y: 50 } : { opacity: 0, x: -100 }}
                  transition={{ duration: 0.3 }} // Transition fluide pour les stories suivantes
                  style={{ display: 'flex', height: '100%', cursor: 'pointer' 
                  }}
                  drag="y" // Permet le mouvement vertical
                  dragConstraints={{ top: -200, bottom: 0 }} // Limite le mouvement vers le haut
                  onDragEnd={(event, info) => {
                  if (info.offset.y < -100) { // Si l'utilisateur glisse vers le haut de 100px
                  closeModal(); // Ferme la story
                  }
                  }}
                  
                  onClick={showNextStory}
                >
                  <Box
                    flex="1"
                    onClick={(e) => {
                      e.stopPropagation();
                      showPreviousStory();
                    }}
                    style={{ cursor: 'pointer' }}
                  ></Box>
                  {/* Affichage image ou vidéo */}
                  {selectedStory.media.endsWith('.mp4') ? (
                    <video
                      src={`${apiUrl}/uploads/stories/${selectedStory.media}`}
                      className="modal-content" // ✅ Ajout de la classe pour adapter le format
                      autoPlay
                      loop={false} // ✅ On désactive la boucle comme Instagram
                      onEnded={showNextStory} // ✅ Passe à la story suivante automatiquement
                      playsInline // ✅ Permet d'éviter le plein écran automatique sur iOS
                    />
                  ) : (
                    <Image
                      src={`${apiUrl}/uploads/stories/${selectedStory.media}`}
                      alt={selectedStory.creator}
                      className="modal-content" // Ajout de la classe pour appliquer le CSS
                    />
                    )}
                  <Box flex="1" onClick={showNextStory} style={{ cursor: 'pointer' }}></Box>
                </motion.div>
              </AnimatePresence>
            </ModalBody>
            <ModalFooter>
            <Button variant="ghost" color="blue.500" mr={3} onClick={closeModal}>
                Fermer
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default StoryList;
